/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-csv': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM3.517 14.841a1.13 1.13 0 00.401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 00-.375-.357 2 2 0 00-.566-.21l-.621-.144a1 1 0 01-.404-.176.37.37 0 01-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 01.246.181.56.56 0 01.12.258h.75a1.1 1.1 0 00-.2-.566 1.2 1.2 0 00-.5-.41 1.8 1.8 0 00-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 01.152.326.5.5 0 01-.085.29.56.56 0 01-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 01-.248-.115.58.58 0 01-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 01.302-.399.8.8 0 01.475-.137q.225 0 .398.097a.7.7 0 01.272.26.85.85 0 01.12.381h.765v-.072a1.33 1.33 0 00-.466-.964 1.4 1.4 0 00-.489-.272 1.8 1.8 0 00-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 00.226-.674v-.076h-.764a.8.8 0 01-.118.363.7.7 0 01-.272.25.9.9 0 01-.401.087.85.85 0 01-.478-.132.83.83 0 01-.299-.392 1.7 1.7 0 01-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z"/>',
    },
});
